<template>
  <div class="app-container">
    <!--工具栏-->
    <div class="head-container">
      <el-input v-if="$erp.enable()" v-model="query.erpId" clearable placeholder="ERP编号搜索" style="width: 130px;"
        class="filter-item" @keyup.enter.native="toQuery" />
      <el-input v-model="query.name" clearable placeholder="输入企业名称搜索" style="width: 200px;" class="filter-item"
        @keyup.enter.native="toQuery" />
      <el-input v-model="query.persons" clearable placeholder="运营人员/拓展人员名称或编码" style="width: 220px;" class="filter-item"
        @keyup.enter.native="toQuery" />
      <el-input v-model="query.managerName" clearable placeholder="区域负责人账号" style="width: 140px;" class="filter-item"
        @keyup.enter.native="toQuery" />
      <quick-select ref="entType" v-model="query.enterpriseType" filterable url="api/dictDetail" display-field="label"
        :params="{ dictName: 'enterprise_type' }" placeholder="企业类型" @change="toQuery" clearable class="filter-item"
        style="width: 100px;" />
      <quick-select ref="level" v-model="query.level" filterable url="api/distributorLeve/list" placeholder="经销商类型"
        @change="toQuery" clearable class="filter-item" style="width: 150px;" />
      <el-select v-model="query.enabled" filterabe clearable placeholder="启用状态" @change="toQuery" class="filter-item"
        style="width: 100px;">
        <el-option value="true" label="启用" />
        <el-option value="false" label="禁用" />
      </el-select>
      <tree-picker v-model="query.areaId" url="api/area" style="width: 150px;" placeholder="经销商区域" clearable flat
        class="filter-item" @change="toQuery" />
      <el-select v-model="query.orderPayType" filterabe clearable placeholder="支付限制" @change="toQuery"
        class="filter-item" style="width: 150px;">
        <el-option value="online" label="仅支持在线支付" />
        <el-option value="offline" label="仅支持线下支付" />
        <el-option value="all" label="不进行支付限制" />
      </el-select>
      <el-input v-model="query.shipWarehouseCode" clearable placeholder="出货仓库代码" style="width: 130px;" class="filter-item"
        @keyup.enter.native="toQuery" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <!-- 新增 -->
      <!-- <div style="display: inline-block;margin: 0px 2px;"> -->
      <el-button v-permission="['DISTRIBUTOR_ADD', 'DISTRIBUTOR_ALL']" class="filter-item" size="mini" type="primary"
        icon="el-icon-plus" @click="add">新增</el-button>
      <el-button v-permission="['DISTRIBUTOR_ADD', 'DISTRIBUTOR_ALL']" class="filter-item" size="mini" type="primary"
        icon="el-icon-upload2" @click="doImport">导入</el-button>
      <el-button v-permission="['DISTRIBUTOR_EXPORT', 'DISTRIBUTOR_ALL']" class="filter-item" size="mini" type="info"
        icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
      <!-- </div> -->
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-edit" :disabled="!selection || !selection.length"
        @click="batchEdit" v-permission="['DISTRIBUTOR_BATCH', 'DISTRIBUTOR_ALL']">批量修改</el-button>
    </div>
    <el-table ref="multipleTable" v-loading="loading" row-key="id" @selection-change="handleSelectionChange"
      :data="data" size="small" style="width: 100%;" border>
      <el-table-column type="selection" width="35" reserve-selection fixed />
      <el-table-column prop="erpId" label="ERP编号" width="120" fixed />
      <el-table-column prop="fullName" label="企业名称" min-width="100" show-overflow-tooltip
        v-if="statementType !== sertaStatementType" />
      <el-table-column prop="name" label="企业简称" min-width="100" show-overflow-tooltip
        v-if="statementType !== sertaStatementType" />
      <el-table-column prop="name" label="企业名称" min-width="100" show-overflow-tooltip
        v-if="statementType === sertaStatementType" />
      <el-table-column prop="enterpriseTypeName" label="企业类型" width="80" />
      <el-table-column label="经销商类型" width="100" prop="levelName" />
      <el-table-column prop="areaName" label="所在区域" width="120" />
      <el-table-column prop="_groups" label="经销商组" min-width="120" show-overflow-tooltip></el-table-column>

      <!-- <el-table-column label="地址" min-width="200" show-overflow-tooltip>
        <template slot-scope="scope">{{scope.row.provinceName}}{{scope.row.cityName}}{{scope.row.districtName}}{{scope.row.address}}</template>
      </el-table-column>-->
      <!-- <el-table-column prop="deptId" label="管理单位" />
      <el-table-column prop="alias" label="经销商别名" />-->
      <el-table-column label="登记时间" :formatter="r => { return new Date(r.registerTime).format('yyyy/MM/dd'); }"
        width="100" />
      <el-table-column label="运营人员" width="130">
        <template slot-scope="scope">{{
          scope.row.managePersonCode ? scope.row.managePerson + "（" +
            scope.row.managePersonCode + "）" : ""
        }}</template>
      </el-table-column>
      <el-table-column label="拓展人员" width="130">
        <template slot-scope="scope">{{
          scope.row.extendPersonCode ? scope.row.extendPerson + "（" +
            scope.row.extendPersonCode + "）" : ""
        }}</template>
      </el-table-column>
      <!-- <el-table-column prop="shipWarehouseCode" label="U9出货仓库代码" width="110" show-overflow-tooltip>        
      </el-table-column> -->
      <el-table-column prop="disabled" label="启用" width="80">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.enabled" active-color="#13ce66" @change="updateState(scope.row)"
            :disabled="!checkPermission(['DISTRIBUTOR_EDIT', 'DISTRIBUTOR_ALL'])" />
        </template>
      </el-table-column>
      <el-table-column width="120px" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="view(scope.row)">详情</el-button>
          <el-button size="mini" type="text" @click="edit(scope.row)"
            v-permission="['DISTRIBUTOR_EDIT', 'DISTRIBUTOR_ALL']">编辑</el-button>
          <!-- <el-button size="mini" type="text" @click="account(scope.row)">账号</el-button> -->
          <el-popover :ref="scope.row.id" placement="top" width="180"
            v-permission="['DISTRIBUTOR_DEL', 'DISTRIBUTOR_ALL']">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini"
                @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
          </el-popover>
        </div>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />

    <eForm ref="form" :is-add="isAdd" />
    <import-form ref="import" />
    <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="batchDialog" title="批量修改"
      width="760px" @closed="handleBatchClosed">
      <el-form ref="batchForm" :model="batchForm" size="small" label-width="115px" label-suffix=":"
        v-if="batchForm">
        <div class="h">
          <el-form-item label="运营人员工号" prop="managePersonCode">
            <el-input v-model="batchForm.managePersonCode" style="width: 247px;" />
          </el-form-item>
          <el-form-item label="运营人员名称" prop="managePerson">
            <el-input v-model="batchForm.managePerson" style="width: 240px;" />
          </el-form-item>
        </div>
        <div class="h">
          <el-form-item label="拓展人员工号" prop="extendPersonCode">
            <el-input v-model="batchForm.extendPersonCode" style="width: 247px;" />
          </el-form-item>
          <el-form-item label="拓展人员名称" prop="extendPerson">
            <el-input v-model="batchForm.extendPerson" style="width: 240px;" />
          </el-form-item>
        </div>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="batchDialog = false">取消</el-button>
        <el-button :loading="batchLoading" type="primary" @click="doBatchSubmit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { del, edit } from "@/api/distributor";
import { get } from "@/api/user";
import eForm from "./form";
import importForm from "./import-form";
import { getStatementType } from "@/api/capitalPool";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
import request from "@/utils/request";

export default {
  components: { eForm, importForm },
  mixins: [initData],
  data() {
    return {
      batchLoading: false,
      batchDialog: false,
      batchForm: null,
      batchRules: {
        managePersonCode: [{ required: true, message: "请输入运营人员工号" }],
        managePerson: [{ required: true, message: "请输入运营人员名称" }],
        extendPersonCode: [{ required: true, message: "请输入拓展人员工号" }],
        extendPerson: [{ required: true, message: "请输入拓展人员名称" }],
      },
      selection: null,
      downloadLoading: false,
      statementType: null,
      sertaStatementType: "serta",
      delLoading: false,
      query: {
        erpId: null,
        name: null,
        enterpriseType: null,
        level: null,
        areaId: null,
        orderPayType: null,
      },
    };
  },
  mounted() {
    getStatementType().then((res) => {
      this.statementType = res;
    });
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/distributor";
      const sort = "id,desc";
      this.params = Object.assign(
        { page: this.page, size: this.size, sort: sort },
        this.query
      );
      return true;
    },
    afterLoad(data) {
      data.forEach((item) => {
        item._groups = "";
        this.getDisGroupByDisErpCode(item);
      });
    },
    handleSelectionChange(rows) {
      this.selection = rows;
    },
    batchEdit() {
      if (this.selection && this.selection.length) {
        let ids = []
        ids = this.selection.map(o => o.enterpriseId)
        this.batchForm = {
          distributorEntIds: ids || [],
          managePersonCode: null,
          managePerson: null,
          extendPersonCode: null,
          extendPerson: null,
        }
        this.batchDialog = true;
      }
    },
    handleBatchClosed() { this.batchForm = null; },
    doBatchSubmit() {
      this.$refs["batchForm"].validate((valid) => {
        if (valid) {
          this.batchLoading = true;
          request({
            url: "api/distributor/staff",
            method: "put",
            data: this.batchForm,
          }).then((_) => {
            this.$notify({
              title: "批量修改成功",
              type: "success",
              duration: 2500,
            });
            this.$refs.multipleTable.clearSelection();
            this.selection = [];
            this.init()
            this.batchDialog = false;
            this.batchLoading = false;
          });
        }
      });
    },
    updateState(d) {
      edit(d).then((_) => {
        this.$notify({
          title: "操作成功",
          type: "success",
          duration: 2500,
        });
      });
    },
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
          this.$refs[id].doClose();
        });
    },
    getName(name, val) {
      let el = this.$refs[name],
        t = "";
      if (el) t = el.getDisplayText(val);
      return t;
    },
    add() {
      this.isAdd = true;
      this.$refs.form && this.$refs.form.resetForm(this.statementType);
    },
    edit(data) {
      this.isAdd = false;
      this.$refs.form &&
        this.$refs.form.resetForm(
          this.statementType,
          JSON.parse(JSON.stringify(data))
        );
    },
    view(data) {
      this.$refs.form &&
        this.$refs.form.resetForm(
          this.statementType,
          JSON.parse(JSON.stringify(data)),
          true
        );
    },
    doImport() {
      if (this.$refs.entType) {
        let v = this.$refs.entType.getValue("企业");
        if (v) {
          this.$refs.import && this.$refs.import.show(v, this.statementType);
        }
      }
    },
    account(data) {
      get({
        size: 1,
        page: 0,
        enterpriseId: data.enterpriseId,
      }).then((res) => {
        if (res && res.content && res.content.length) {
          this.isAdd = false;
          let d = res.content[0];
          d._roleIds = d.roles.map((r) => {
            return r.id;
          });
          this.$refs.account && this.$refs.account.resetForm(res.content[0]);
        } else {
          this.isAdd = true;
          this.$refs.account &&
            this.$refs.account.resetForm(null, null, {
              enterpriseId: data.enterpriseId,
            });
        }
      });
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/distributor/download", this.params)
        .then((result) => {
          downloadFile(result, "经销商信息", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
    getDisGroupByDisErpCode(row) {
      request({
        url: "api/distributorGroup",
        method: "get",
        params: { disCode: row.erpId, page: 0, size: 9999 },
      }).then((res) => {
        let names = [];
        res.content.forEach((item) => {
          names.push(item.name);
        });
        row._groups = names.join(",");
      });
    },
  },
};
</script>